import { Popup } from 'components/Organization/Popup';

interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: string;
  label?: string;
}

export function IconButton({ label, icon, ...props }: IconButtonProps) {
  if (!label) {
    return (
      <button
        {...props}
        className="w-8 h-8 flex items-center justify-center rounded text-action hover:bg-action-tint"
      >
        <i className={icon} />
      </button>
    );
  }

  return (
    <Popup label={label}>
      <button
        {...props}
        className="w-8 h-8 flex items-center justify-center rounded text-action hover:bg-action-tint"
      >
        <i className={icon} />
      </button>
    </Popup>
  );
}
