import { clsx } from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';

type Option = {
  value: any;
  text: string;
};

interface UserStatusSelectProps {
  /** List of options. */
  options: Option[];

  /** Current value of the select. */
  value: any;

  /** Callback fired when a new value is selected. */
  onChange: (value: any) => void;
}

export function UserStatusSelect({
  value,
  options,
  onChange
}: UserStatusSelectProps) {
  const selectedOption = options.find(option => option.value === value);

  const handleChange = (option: Option) => {
    onChange(option.value);
  };

  return (
    <Listbox value={selectedOption} onChange={handleChange}>
      <ListboxButton className="flex items-center font-sans font-semibold text-base">
        <div className="p-2.5 py-2 rounded-l border border-r-0 border-navy-300 bg-charcoal-25">
          {i18n.ft(messages.viewing) + ':'}
        </div>
        <div className="px-2.5 py-2 rounded-r border border-l-0 border-navy-300 bg-action text-white pr-4 min-w-60 flex items-center">
          <span>{selectedOption?.text}</span>
          <i className="fa-solid fa-caret-down ml-auto" />
        </div>
      </ListboxButton>

      <ListboxOptions
        anchor="bottom"
        className="w-[var(--button-width)] mt-1 p-1 bg-white ring-2 ring-action rounded-lg outline-none"
      >
        {options.map((option, idx) => (
          <ListboxOption
            key={option.value}
            value={option}
            className={clsx(
              'font-sans text-black p-2 rounded data-[focus]:bg-[#F7F8F9] data-[selected]:bg-action-tint data-[selected]:font-semibold',
              idx === options.length - 1 && 'rounded-b-lg'
            )}
          >
            {option.text}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}
